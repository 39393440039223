import { CLEAR_NOEUD_OUVERT, SET_NOEUD_OUVERT } from '../typeKey'

export interface INoeudOuvertState {
  noeudOuvert: string[]
}

export const defaultStateNoeudOuvert: INoeudOuvertState = {
  noeudOuvert: []
}

export default function noeudOuvertReducer(state = defaultStateNoeudOuvert, action: any) {
  switch (action.type) {
    case SET_NOEUD_OUVERT:
      return { ...state, noeudOuvert: action.payload }
    case CLEAR_NOEUD_OUVERT:
      return defaultStateNoeudOuvert
    default:
      return state
  }
}
