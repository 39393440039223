import { createContext } from 'react'
import { AuthentificationContextType } from './interface/interfaceAuthentification'

export const authentificationContextDefaultValue = {
  environnement: '',
  fetch: async (input: RequestInfo | URL, init?: RequestInit) => {
    const result = await fetch(input, init)
    if (result.ok) {
      return result
    }
    throw new Error(await result.text())
  },
  prenomNom: '',
  nom: '',
  prenom: '',
  matricule: '',
  idep: ''
}
const AuthentificationContext = createContext<AuthentificationContextType>(authentificationContextDefaultValue)

export default AuthentificationContext
