import { makeStyles } from '@mui/styles'
import ListeAgent from './ListeAgent'
import RechercheAgent from './RechercheAgent'
import { Progress } from '../Utils/Progress'
import { InseeTheme } from '../../theme/theme'
import { useFetchContext } from '../../hook/UseFetchContext'

const useStyles = makeStyles(() => ({
  tree: {
    margin: 0,
    padding: 0,
    overflowY: 'scroll',
    overflowX: 'hidden',
    maxHeight: '70vh',
    minHeight: '55vh'
  }
}))

export default function BlocAgent() {
  const classes = useStyles(InseeTheme)
  const { state } = useFetchContext()

  return state.agentTotalState.agentsTotal.length > 0 ? (
    <div>
      <RechercheAgent />
      <div className={classes.tree}>
        <ListeAgent />
      </div>
    </div>
  ) : (
    <Progress texte="Chargement des agents en cours..." />
  )
}
