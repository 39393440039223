import { useEffect } from 'react'
import { List, ListItemAvatar, ListItemButton, ListItemText, Typography } from '@mui/material'
import AvatarAgent from './AvatarAgent'
import { useNavigate } from 'react-router'
import { affichageNomPrenom } from '../Utils/fonctions'
import { IAgent } from '../../lib/interface/agent'
import { useFetchContext } from '../../hook/UseFetchContext'
import { CLEAR_AGENT, GET_AGENT_SUCCESS, SET_AGENT_SELECTED } from '../../lib/typeKey'

const styles = {
  avatar: { color: 'primary.main', backgroundColor: 'secondary.main' },
  gras: { fontWeight: 'bold' },
  hover: { '&:focus, &:hover': { backgroundColor: 'hover.cadre' } },
  selectedHover: { backgroundColor: 'selected.cadre', '&:focus, &:hover': { backgroundColor: 'selectedHover.cadre' } }
}

export default function ListeAgent() {
  const { state, dispatch } = useFetchContext()
  const agents = state.agentState.agents
  const agentsTotal = state.agentTotalState.agentsTotal
  const agentSelected = state.agentState.agentSelected
  const noeudSelected = state.noeudSelectedState.noeudSelected
  const noeuds = state.dimensionState.noeuds
  let navigate = useNavigate()

  useEffect(() => {
    let newList: string[] = []
    const noeudComplet = noeuds.filter((noeud: INoeudDimension) => noeud.identifiantNoeudDimension === noeudSelected)

    if (noeudComplet?.[0]) {
      newList = noeudComplet[0].valeurs
      if (newList.length > 0) {
        dispatch({
          type: GET_AGENT_SUCCESS,
          payload: agentsTotal.filter((agent: IAgent) => newList.includes(agent.affectation.unite))
        })
      } else {
        dispatch({ type: CLEAR_AGENT })
      }
    }
  }, [agentsTotal, noeudSelected, noeuds])

  const handleAgentClick = (value: IAgent) => () => {
    dispatch({ type: SET_AGENT_SELECTED, payload: value })
    navigate(`/${value.identifiantsAgent.idep.toLowerCase()}`)
  }

  return (
    <div>
      {agents.length > 0 ? (
        <List dense>
          {agents.map((agent: IAgent) => {
            const labelId = `${agent.identifiantsAgent.matricule}`
            const responsable = agent.affectation.estResponsable ? ' (responsable)' : ''
            const select = agentSelected
              ? agentSelected.identifiantsAgent.matricule === agent.identifiantsAgent.matricule
              : false
            return (
              <ListItemButton
                key={labelId}
                onClick={handleAgentClick(agent)}
                autoFocus={select}
                sx={select ? styles.selectedHover : styles.hover}
                data-cy={`agent-${agent.identifiantsAgent.idep}`}
              >
                <ListItemAvatar>
                  <AvatarAgent
                    agent={agent}
                    styles={styles.avatar}
                  />
                </ListItemAvatar>
                <ListItemText
                  id={labelId}
                  primary={
                    <Typography sx={select ? styles.gras : {}}>
                      {affichageNomPrenom(agent.etatCivil.nomUsage, agent.etatCivil.prenomUsuel) + responsable}
                    </Typography>
                  }
                />
              </ListItemButton>
            )
          })}
        </List>
      ) : (
        <Typography align="center">Aucun agent affecté sur l'unité sélectionnée</Typography>
      )}
    </div>
  )
}
