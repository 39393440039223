import { Typography } from '@mui/material'

export default function Securite() {
  document.title = "Sécurité | Annuaire de l'Insee"

  return (
    <>
      <br />
      <Typography
        variant="h4"
        component="h2"
        color="inherit"
        style={{ fontWeight: 'bold' }}
      >
        Sécurité du système « API RH »
      </Typography>
      <br />
      <Typography
        variant="body1"
        component="p"
        color="inherit"
      >
        Le site trombi.insee.fr ainsi que l'API qui l'alimente ont fait l’objet d’une décision d’homologation prononcée
        le 16 septembre 2022 par l’autorité d’homologation pour le compte de l’autorité qualifiée de la sécurité du
        système d’information.
      </Typography>
    </>
  )
}
