import { CLEAR_NOEUD_SELECTED, SET_NOEUD_SELECTED } from '../typeKey'

export interface INoeudSelectedState {
  noeudSelected: string | null
}

export const defaultStateSelectedNoeud: INoeudSelectedState = {
  noeudSelected: null
}

export default function noeudSelectedReducer(state = defaultStateSelectedNoeud, action: any) {
  switch (action.type) {
    case SET_NOEUD_SELECTED:
      return { ...state, noeudSelected: action.payload }
    case CLEAR_NOEUD_SELECTED:
      return defaultStateSelectedNoeud
    default:
      return state
  }
}
