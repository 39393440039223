import { FormEvent, useCallback, useState } from 'react'
import { Autocomplete, TextField } from '@mui/material'
import { champSansAccent, compareUnitesByLibelle, getIdNoeudsAscendants } from '../Utils/fonctions'
import { useFetchContext } from '../../hook/UseFetchContext'
import { CLEAR_AGENT, SET_NOEUD_OUVERT, SET_NOEUD_SELECTED } from '../../lib/typeKey'

export default function RechercheUnite() {
  const { state, dispatch } = useFetchContext()
  const noeuds = state.dimensionState.noeuds
  const [inputValue, setInputValue] = useState<INoeudDimension | null>(null)

  const searchUniteByLibelle = (recherche: INoeudDimension) => {
    let recherche2 = ''

    if (recherche) {
      recherche2 = champSansAccent(String(recherche))
    }
    const resultats = noeuds.filter((noeud: INoeudDimension) => champSansAccent(noeud.libelle).includes(recherche2))

    resultats.sort(compareUnitesByLibelle)
    if (resultats.length > 0) {
      setInputValue(resultats[0])
    } else {
      setInputValue(recherche)
    }
    return resultats
  }

  const getListeIdNoeudAscendants = (noeud: INoeudDimension | null) => {
    let noeudsAscendants: string[] = []
    let idNoeudSelected = null
    if (noeud) {
      idNoeudSelected = noeud.identifiantNoeudDimension
      noeudsAscendants = getIdNoeudsAscendants(noeuds, noeud)
    } else {
      // s'il n'y a pas de noeud, suppression des agents dans le store
      dispatch({ type: CLEAR_AGENT })
    }
    if (typeof noeud !== 'object') {
      dispatch({ type: CLEAR_AGENT })
    }
    dispatch({ type: SET_NOEUD_OUVERT, payload: noeudsAscendants })
    dispatch({ type: SET_NOEUD_SELECTED, payload: idNoeudSelected })
  }

  const label = "Recherche d'une unité par libellé ou par timbre"

  const miseEnformeUniteName = useCallback(
    (unite: INoeudDimension | string) => {
      if (typeof unite === 'object' && unite.libelle) {
        return unite.libelle
      }
      if (!inputValue) {
        return ''
      }
      return inputValue.libelle
    },
    [inputValue]
  )

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    getListeIdNoeudAscendants(inputValue)
    event.preventDefault()
  }

  const onChange = useCallback((_event: any, value: any) => {
    getListeIdNoeudAscendants(value)
  }, [])

  const filterOptions = useCallback((_option: any, value: any) => {
    return searchUniteByLibelle(value.inputValue)
  }, [])

  const renderInput = useCallback((params: any) => {
    return (
      <TextField
        {...params}
        variant="outlined"
        label={label}
        margin="normal"
        color="secondary"
        slotProps={{
          inputLabel: {
            ...params.inputProps,
            'aria-label': `${label} Les résultats sont affichés à la volée`
          }
        }}
      />
    )
  }, [])

  return (
    <form onSubmit={onSubmit}>
      <Autocomplete
        clearText={'Effacer la saisie'}
        clearOnEscape={true}
        options={noeuds}
        getOptionLabel={miseEnformeUniteName}
        filterOptions={filterOptions}
        aria-label={label}
        freeSolo
        onChange={onChange}
        renderInput={renderInput}
      />
    </form>
  )
}
