import { IAgent } from '../interface/agent'
import { compareAgentsByName } from '../../components/Utils/fonctions'
import { initialUnite, IUnite } from '../interface/unite'
import {
  CLEAR_AGENT,
  GET_AGENT_SUCCESS,
  GET_PHOTO_AGENT_SUCCESS,
  GET_PHOTO_CONNECTED_AGENT_SUCCESS,
  GET_UNITE_AGENT_SELECTED_SUCCESS,
  SET_AGENT_SELECTED
} from '../typeKey'

export interface IAgentState {
  agents: IAgent[]
  agentSelected: IAgent | null
  uniteAgentSelected: IUnite
  photoAgent: Map<string, string>
  photoConnectedAgent: string
  erreur: string
}

export const defaultStateAgent: IAgentState = {
  agents: [],
  agentSelected: null,
  uniteAgentSelected: initialUnite,
  photoAgent: new Map(),
  photoConnectedAgent: '',
  erreur: ''
}

const sortAgents = (payload: any) => {
  return payload.sort(compareAgentsByName)
}

export default function agentReducer(state = defaultStateAgent, action: any) {
  switch (action.type) {
    case GET_AGENT_SUCCESS:
      return { ...state, agents: sortAgents(action.payload), erreur: '' }
    case SET_AGENT_SELECTED:
      return { ...state, agentSelected: action.payload }
    case GET_UNITE_AGENT_SELECTED_SUCCESS:
      return { ...state, uniteAgentSelected: action.data, erreur: '' }
    case GET_PHOTO_AGENT_SUCCESS:
      return {
        ...state,
        photoAgent: new Map(Object.entries({ ...state.photoAgent, [action.data.matricule]: action.data.value }))
      }
    case GET_PHOTO_CONNECTED_AGENT_SUCCESS:
      return { ...state, photoConnectedAgent: action.data.value }
    case CLEAR_AGENT:
      return {
        agents: [],
        agentSelected: null,
        uniteAgentSelected: {},
        photoAgent: new Map(),
        photoConnectedAgent: state.photoConnectedAgent, // Keep the value for the connected agent photo
        erreur: ''
      }
    default:
      return state
  }
}
