import { CLEAR_AGENT_TOTAL, GET_AGENT_TOTAL_SUCCESS } from '../typeKey'
import { compareAgentsByName } from '../../components/Utils/fonctions'
import { IAgent } from '../interface/agent'

export interface IAgentTotalState {
  agentsTotal: IAgent[]
  erreur: string
}

export const defaultStateAgentTotal: IAgentTotalState = {
  agentsTotal: [],
  erreur: ''
}

export default function agentTotalReducer(state = defaultStateAgentTotal, action: any) {
  switch (action.type) {
    case GET_AGENT_TOTAL_SUCCESS: {
      const newAgents = action.data.content
      newAgents.sort(compareAgentsByName)
      return { ...state, agentsTotal: newAgents, erreur: '' }
    }
    case CLEAR_AGENT_TOTAL:
      return defaultStateAgentTotal
    default:
      return state
  }
}
