import BlocPhoto from './BlocPhoto/BlocPhoto'
import BlocAgent from './BlocAgent/BlocAgent'
import BlocUnite from './BlocUnite/BlocUnite'
import { Grid } from '@mui/material'

export default function App() {
  document.title = "Annuaire de l'Insee"
  return (
    <Grid
      container
      style={{ width: '99.5%', marginTop: '1em', marginBottom: '0em' }}
      spacing={2}
      justifyContent="center"
      alignItems="stretch"
    >
      <Grid size={{ xs: 12, md: 4 }}>
        <BlocUnite />
      </Grid>
      <Grid size={{ xs: 12, md: 4 }}>
        <BlocAgent />
      </Grid>
      <Grid size={{ xs: 12, md: 4 }}>
        <BlocPhoto />
      </Grid>
    </Grid>
  )
}
