import { createTheme, responsiveFontSizes } from '@mui/material'

const insee = createTheme({
  palette: {
    primary: { main: '#F2F2F2' },
    secondary: { main: '#A92B40' },
    error: { main: '#e57373' },
    warning: { main: '#ffb74d' },
    info: { main: '#64b5f6' },
    success: { main: '#81c784' },
    canvas: { main: '#F7F7F7' },
    selected: { cadre: '#F5B8C2', texte: '#3F0714' },
    hover: { cadre: '#FFDAE0' },
    selectedHover: { cadre: '#DF8292' }
  } as never,
  components: {
    MuiAutocomplete: {
      styleOverrides: {
        option: {
          '&.Mui-focused': {
            backgroundColor: '#F5B8C2 !important'
          }
        }
      }
    }
  } as never
})

export const InseeTheme = responsiveFontSizes(insee)
