import { useEffect } from 'react'
import { Avatar } from '@mui/material'
import { getInitiales } from '../Utils/fonctions'
import { IAgent } from '../../lib/interface/agent'
import { useFetchContext } from '../../hook/UseFetchContext'
import { GET_PHOTO_AGENT_SUCCESS } from '../../lib/typeKey'

interface MyAvatarProps {
  agent: IAgent
  styles: {}
}

const MyAvatar = (props: MyAvatarProps) => {
  const { agent, styles } = props
  const { state, fetchGetPhoto } = useFetchContext()

  useEffect(() => {
    fetchGetPhoto(agent.identifiantsAgent.matricule, GET_PHOTO_AGENT_SUCCESS)
  }, [agent.identifiantsAgent.matricule, fetchGetPhoto])

  return (
    <Avatar
      aria-hidden="true"
      sx={styles}
      alt={''}
      src={'data:image/jpeg;base64,' + state.agentState.photoAgent.get(`${agent.identifiantsAgent.matricule}`)}
    >
      {getInitiales(agent.etatCivil.nomUsage, agent.etatCivil.prenomUsuel)}
    </Avatar>
  )
}

export default MyAvatar
