import { createContext, Dispatch, ReactNode, useCallback, useContext, useMemo, useReducer } from 'react'
import AuthentificationContext from '../authentification/AuthentificationContext'
import agentReducer, { defaultStateAgent, IAgentState } from '../lib/reducers/AgentReducer'
import agentTotalReducer, { defaultStateAgentTotal, IAgentTotalState } from '../lib/reducers/AgentTotalReducer'
import dimensionReducer, { defaultStateDimension, INoeudDimensionState } from '../lib/reducers/DimensionReducer'
import healthcheckReducer, { defaultStateHealthcheck, IHealthCheckState } from '../lib/reducers/HealthcheckReducer'
import noeudOuvertReducer, { defaultStateNoeudOuvert, INoeudOuvertState } from '../lib/reducers/NoeudOuvertReducer'
import noeudSelectedReducer, {
  defaultStateSelectedNoeud,
  INoeudSelectedState
} from '../lib/reducers/NoeudSelectedReducer'
import getConfiguration from '../configuration'
import { GET_HEALTHCHECK } from '../lib/typeKey'

interface IState {
  agentState: IAgentState
  agentTotalState: IAgentTotalState
  dimensionState: INoeudDimensionState
  healthcheckState: IHealthCheckState
  noeudOuvertState: INoeudOuvertState
  noeudSelectedState: INoeudSelectedState
}

const initialState: IState = {
  agentState: defaultStateAgent,
  agentTotalState: defaultStateAgentTotal,
  dimensionState: defaultStateDimension,
  healthcheckState: defaultStateHealthcheck,
  noeudOuvertState: defaultStateNoeudOuvert,
  noeudSelectedState: defaultStateSelectedNoeud
}

export const FetchContext = createContext<{
  state: IState
  fetchGetPhoto: (matricule: string, type: string) => void
  fetchGetData: (url: string, type: string) => void
  fetchHealthCheck: () => void
  dispatch: Dispatch<any>
}>({
  state: initialState,
  fetchGetPhoto: () => {},
  fetchGetData: () => {},
  fetchHealthCheck: () => {},
  dispatch: () => {}
})

const combineReducers = (slices: any) => (state: any, action: any) =>
  Object.keys(slices).reduce((acc, prop) => ({ ...acc, [prop]: slices[prop](acc[prop], action) }), state)

const rootReducer = combineReducers({
  agentState: agentReducer,
  agentTotalState: agentTotalReducer,
  dimensionState: dimensionReducer,
  healthcheckState: healthcheckReducer,
  noeudOuvertState: noeudOuvertReducer,
  noeudSelectedState: noeudSelectedReducer
})

export const FetchProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(rootReducer, initialState)
  const { fetch } = useContext(AuthentificationContext)

  const fetchGetPhoto = useCallback(
    async (matricule: string, type: string) => {
      const baseUrl = await getConfiguration<string>('BACK_END_PATH')

      try {
        const response = await fetch(`${baseUrl}/photo/${matricule}`, {
          method: 'GET',
          headers: { accept: 'image/jpeg' }
        })
        if (response.ok) {
          const arrayBuffer = await response.arrayBuffer()
          const byteArray = new Uint8Array(arrayBuffer)
          const charArray = Array.from(byteArray, (byte) => String.fromCharCode(byte))
          const binaryString = charArray.join('')
          const data = btoa(binaryString)

          dispatch({ type, data: { matricule, value: data } })
        }
      } catch (error) {
        console.error('Fetch error:', error)
      }
    },
    [fetch]
  )

  const fetchGetData = useCallback(
    async (url: string, type: string) => {
      const baseUrl = await getConfiguration<string>('BACK_END_PATH')
      try {
        const response = await fetch(`${baseUrl}/${url}`, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        if (response.ok) {
          const data = response.headers.get('content-length') ? await response.text() : await response.json()
          dispatch({ type, data })
        }
      } catch (error) {
        console.error('Fetch error:', error)
      }
    },
    [fetch]
  )

  const fetchHealthCheck = useCallback(async () => {
    const baseUrl = await getConfiguration<string>('BACK_END_PATH')
    try {
      const response = await fetch(`${baseUrl}/healthcheck`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
      })
      if (response.ok) {
        dispatch({ type: GET_HEALTHCHECK, isOk: true })
      }
    } catch (error) {
      console.error('Fetch error:', error)
    }
  }, [fetch])

  const contextValue = useMemo(
    () => ({ state, fetchGetPhoto, fetchGetData, fetchHealthCheck, dispatch }),
    [state, fetchGetPhoto, fetchGetData, fetchHealthCheck]
  )

  return <FetchContext.Provider value={contextValue}>{children}</FetchContext.Provider>
}
