import ErrorBoundary from './ErrorBoundary'

function ErrorMaintenance() {
  return (
    <ErrorBoundary
      title="Maintenance !"
      description="L'annuaire est actuellement indisponible, Veuillez nous excuser pour la gêne occasionnée"
    />
  )
}

export default ErrorMaintenance
