import { Outlet } from 'react-router'
import Header from './Header'
import Footer from './Footer'

function Layout() {
  return (
    <div style={{ position: 'absolute', minHeight: '100%', width: '100%' }}>
      <a
        className="evitement"
        href="#contenu"
      >
        Aller au contenu
      </a>

      <Header />
      <main
        id="contenu"
        style={{
          marginLeft: '0.5%',
          marginRight: '0.5%',
          minHeight: '75vh',
          paddingBottom: '60px'
        }}
      >
        <Outlet />
      </main>
      <Footer />
    </div>
  )
}

export default Layout
