import { ReactNode, useCallback } from 'react'
import useLoading from '../hook/UseLoading'
import { Progress } from '../components/Utils/Progress'
import AuthentificationOidc from './AuthentificationOidc'

function AuthentificationProvider({ children }: Readonly<{ children: ReactNode }>) {
  const [isLoading] = useLoading(useCallback(async () => {}, []))
  if (isLoading) {
    return <Progress texte="Chargement de l'environnement en cours" />
  }

  return <AuthentificationOidc>{children}</AuthentificationOidc>
}

export default AuthentificationProvider
