export const GET_AGENT_SUCCESS = 'GET_AGENT_SUCCESS'
export const CLEAR_AGENT = 'CLEAR_AGENT'
export const SET_AGENT_SELECTED = 'SET_AGENT_SELECTED'
export const GET_UNITE_AGENT_SELECTED_SUCCESS = 'GET_UNITE_AGENT_SELECTED_SUCCESS'
export const GET_PHOTO_AGENT_SUCCESS = 'GET_PHOTO_AGENT_SUCCESS'
export const GET_PHOTO_CONNECTED_AGENT_SUCCESS = 'GET_PHOTO_CONNECTED_AGENT_SUCCESS'
export const GET_AGENT_TOTAL_SUCCESS = 'GET_AGENT_TOTAL_SUCCESS'
export const CLEAR_AGENT_TOTAL = 'CLEAR_AGENT_TOTAL'

export const GET_DIMENSION_SUCCESS = 'GET_DIMENSION_SUCCESS'
export const CLEAR_DIMENSION = 'CLEAR_DIMENSION'

export const GET_HEALTHCHECK = 'GET_HEALTHCHECK'

export const SET_NOEUD_OUVERT = 'SET_NOEUD_OUVERT'
export const CLEAR_NOEUD_OUVERT = 'CLEAR_NOEUD_OUVERT'
export const SET_NOEUD_SELECTED = 'SET_NOEUD_SELECTED'
export const CLEAR_NOEUD_SELECTED = 'CLEAR_NOEUD_SELECTED'
