import { NavLink } from 'react-router'
import { Box, Paper } from '@mui/material'
import footerRoute from '../../routes/FooterRoute'
import { RouterType } from '../../routes/interface/interfaceRoute'
import { useCallback } from 'react'
import { InseeTheme } from '../../theme/theme'

export default function Footer() {
  const style = useCallback(
    ({ isActive }: { isActive: boolean }) =>
      isActive ? { color: InseeTheme.palette.secondary.main } : { color: 'black' },
    []
  )

  return (
    <Paper
      sx={{ width: '100%', height: '50px', position: 'absolute', bottom: 0 }}
      elevation={12}
      component="footer"
    >
      <Box
        sx={{
          width: '80%',
          height: '100%',
          justifyContent: 'space-between',
          display: 'flex',
          alignItems: 'center',
          margin: 'auto'
        }}
      >
        {footerRoute.map((pageRoute: RouterType) => (
          <NavLink
            to={pageRoute.path}
            key={pageRoute.title}
            style={style}
            data-cy={pageRoute.testId}
          >
            {pageRoute.title}
          </NavLink>
        ))}
      </Box>
    </Paper>
  )
}
