import { ReactElement } from 'react'
import { RouteObject } from 'react-router'

export interface RouterType {
  path: string
  title: string
  testId: string
  element: ReactElement
  children: RouterType[]
}

export const transformRoutes = (routes: RouterType[]): RouteObject[] => {
  return routes.map((route) => {
    const { path, element, children } = route
    const transformedPath = path.startsWith('/') ? path.slice(1) : path

    return children && children.length > 0
      ? {
          path: transformedPath,
          element: element,
          children: transformRoutes(children)
        }
      : {
          path: transformedPath,
          element: element
        }
  })
}
