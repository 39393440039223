import React from 'react'
import './index.css'
import { createRoot } from 'react-dom/client'
import Root from './components/Root'
import AuthentificationProvider from './authentification/AuthentificationProvider'
import { FetchProvider } from './providers/FetchProvider'
import { InseeTheme } from './theme/theme'
import { ThemeProvider } from '@mui/material'

createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <AuthentificationProvider>
      <FetchProvider>
        <ThemeProvider theme={InseeTheme}>
          <Root />
        </ThemeProvider>
      </FetchProvider>
    </AuthentificationProvider>
  </React.StrictMode>
)
