import ErrorBoundary from './ErrorBoundary'

function ErrorInvalid() {
  return (
    <ErrorBoundary
      title="Oups, page non trouvée !"
      description="Désolé, nous n'avons pas pu trouver la page que vous recherchez.
                       Peut-être avez-vous mal saisi l'URL ? Assurez-vous de vérifier votre orthographe."
      errorCode={404}
    />
  )
}

export default ErrorInvalid
