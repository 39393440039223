import { useEffect, useState } from 'react'
import AvatarAgent from '../BlocAgent/AvatarAgent'
import { Card, CardContent, CardHeader, Link as LinkMUI, Typography } from '@mui/material'
import { getAdresseUnite, getCivilite } from '../Utils/fonctions'
import { IBalf } from '../../lib/interface/agent'
import { useFetchContext } from '../../hook/UseFetchContext'
import { GET_UNITE_AGENT_SELECTED_SUCCESS } from '../../lib/typeKey'
import { InseeTheme } from '../../theme/theme'

const styles = {
  root: {
    marginTop: '1em',
    padding: 0,
    maxHeight: '80vh',
    minHeight: '62vh',
    backgroundColor: 'canvas.main',
    overflow: 'auto',
    overflowY: 'scroll'
  },
  details: { display: 'flex', flexDirection: 'column' },
  content: { flex: '1 0 auto' },
  avatar: {
    width: 100,
    height: 100,
    marginRight: 0,
    color: 'primary.main',
    backgroundColor: 'secondary.main',
    fontSize: '3em'
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: InseeTheme.spacing(1),
    paddingBottom: InseeTheme.spacing(1)
  },
  text: { marginBottom: '0.5em' }
}

export default function BlocPhoto() {
  const { state, fetchGetData } = useFetchContext()
  const agent = state.agentState.agentSelected
  const uniteAgent = state.agentState.uniteAgentSelected
  const [showBALF, setShowBALF] = useState<boolean>(true)

  useEffect(() => {
    if (agent?.affectation) {
      fetchGetData(`unite/${agent.affectation.unite}`, GET_UNITE_AGENT_SELECTED_SUCCESS)
    }
  }, [agent])

  const onBalfIconClick = () => {
    setShowBALF((prevState) => !prevState)
  }

  if (!uniteAgent) {
    return <></>
  }

  return (
    agent && (
      <Card
        sx={styles.root}
        data-cy={`fiche-agent-${agent.identifiantsAgent.idep}`}
      >
        <CardHeader
          avatar={
            <AvatarAgent
              styles={styles.avatar}
              agent={agent}
            />
          }
          title={
            <Typography
              variant="h6"
              component="h2"
              color="inherit"
            >
              {getCivilite(agent)}
            </Typography>
          }
          subheader={
            <Typography
              component="p"
              variant="subtitle2"
              color="textSecondary"
              sx={styles.text}
            >
              <strong>Idep : </strong> {agent.identifiantsAgent.idep}
            </Typography>
          }
        />
        <CardContent
          sx={styles.content}
          style={{ paddingBottom: 0 }}
        >
          <Typography
            component="p"
            variant="subtitle2"
            color="textSecondary"
            sx={styles.text}
          >
            <strong>Grade : </strong>
            {agent.carriere.grade.libelle}
          </Typography>

          <Typography
            component="p"
            variant="subtitle2"
            color="textSecondary"
            sx={styles.text}
          >
            {agent.affectation.estResponsable ? (
              <strong>{"Est responsable de l'unité : "}</strong>
            ) : (
              <strong>{"Est agent de l'unité : "}</strong>
            )}
            {uniteAgent.libelleLong}
          </Typography>

          <Typography
            component="p"
            variant="subtitle2"
            color="textSecondary"
            sx={styles.text}
          >
            <strong>Timbre : </strong>
            {uniteAgent.timbre}
          </Typography>

          <Typography
            component="p"
            variant="subtitle2"
            color="textSecondary"
            sx={styles.text}
          >
            <strong>Fonction : </strong>
            {agent.affectation.fonction?.libelle}
          </Typography>

          <Typography
            component="p"
            variant="subtitle2"
            color="textSecondary"
            sx={styles.text}
          >
            <strong>Bureau : </strong>
            {agent.coordonnees?.bureau}
          </Typography>

          <Typography
            component="p"
            variant="subtitle2"
            color="textSecondary"
            sx={styles.text}
          >
            <strong>Téléphone : </strong>
            {agent.coordonnees?.telephoneProfessionnel}
          </Typography>

          <Typography
            component="p"
            variant="subtitle2"
            color="textSecondary"
            sx={styles.text}
          >
            <strong>Portable professionnel : </strong>
            {agent.coordonnees?.portableProfessionnel}
          </Typography>

          <Typography
            component="p"
            variant="subtitle2"
            color="textSecondary"
            sx={styles.text}
          >
            <strong>Mail : </strong>
            {agent.coordonnees?.email && (
              <LinkMUI href={'mailto:' + agent.coordonnees.email}>
                <Typography
                  component="span"
                  variant="subtitle2"
                  color="textSecondary"
                  sx={{ ...styles.text, textDecoration: 'underline' }}
                >
                  {agent.coordonnees.email}
                </Typography>
              </LinkMUI>
            )}
          </Typography>

          {agent?.balf?.length > 0 ? (
            <div>
              <Typography
                variant="subtitle2"
                color="textSecondary"
                sx={styles.text}
              >
                <strong>
                  Boîtes fonctionnelles de l'agent :{' '}
                  <button
                    onClick={onBalfIconClick}
                    style={{ cursor: 'pointer', border: 'none', backgroundColor: 'transparent' }}
                  >
                    {showBALF ? '▼' : '▶'}
                  </button>
                </strong>
              </Typography>
              {showBALF && (
                <ul style={{ marginTop: -6, marginBottom: 3 }}>
                  {agent.balf?.map((balf: IBalf) => (
                    <li key={balf.nom}>
                      <LinkMUI href={'mailto:' + balf.adresse}>
                        <Typography
                          component="span"
                          variant="subtitle2"
                          color="textSecondary"
                          sx={{ ...styles.text, textDecoration: 'underline' }}
                        >
                          {balf.nom}
                        </Typography>
                      </LinkMUI>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          ) : (
            <div />
          )}

          <Typography
            component="p"
            variant="subtitle2"
            color="textSecondary"
            sx={styles.text}
          >
            <strong>Adresse de l'unité : </strong>
            {getAdresseUnite(uniteAgent).map((value) => (
              <p
                key={value}
                style={{ margin: 0 }}
              >
                {value}
              </p>
            ))}
          </Typography>
        </CardContent>
      </Card>
    )
  )
}
