import Typography from '@mui/material/Typography'
import { Box } from '@mui/material'

interface ErrorBoundaryProps {
  title: string
  description: string
  errorCode?: number
}

function ErrorBoundary(props: Readonly<ErrorBoundaryProps>) {
  const { title, description, errorCode } = props

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        m: 'auto',
        maxWidth: '450px',
        minHeight: '100vh',
        textAlign: 'center',
        gap: 2
      }}
    >
      <Typography
        variant="h4"
        component="h1"
        sx={{ fontWeight: 'bold' }}
      >
        {title}
      </Typography>
      <Typography
        variant="body1"
        component="p"
        sx={{ color: 'gray' }}
      >
        {description}
      </Typography>
      {errorCode && (
        <Typography
          variant="h1"
          component="p"
          sx={{ fontWeight: 'bold', color: 'primary.dark', letterSpacing: '8px' }}
        >
          {errorCode}
        </Typography>
      )}
    </Box>
  )
}

export default ErrorBoundary
