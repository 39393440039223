import { IAgent, ICoordonnee, IIdentifiantAgent } from '../../lib/interface/agent'
import { IAdresseEtranger, IAdresseFrance, IUnite } from '../../lib/interface/unite'

export const champSansAccent = (champ: string) => {
  return champ
    .trim()
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
}

export const telephoneNettoye = (coordonnees: ICoordonnee) => {
  return coordonnees?.telephoneProfessionnel
    ? coordonnees.telephoneProfessionnel.trim().toLowerCase().replace(/\D+/g, '')
    : ''
}

export const portableProfessionnelNettoye = (coordonnees: ICoordonnee) => {
  return coordonnees?.portableProfessionnel
    ? coordonnees.portableProfessionnel.trim().toLowerCase().replace(/\D+/g, '')
    : ''
}

export const bureauNettoye = (coordonnees: ICoordonnee) => {
  return coordonnees?.bureau ? coordonnees.bureau.trim().toLowerCase() : ''
}

export const idepNettoye = (identifiantsAgent: IIdentifiantAgent) => {
  return identifiantsAgent?.idep ? identifiantsAgent.idep.trim().toLowerCase() : ''
}

export const getInitiales = (nom: string, prenom: string) => {
  return (prenom.charAt(0) + nom.charAt(0)).toUpperCase()
}

export const affichageNomPrenom = (nom: string, prenom: string) => {
  return `${nom.toUpperCase()} ${prenom}`
}

export const getIdNoeudsAscendants = (noeuds: INoeudDimension[], noeud: INoeudDimension) => {
  let noeudsAscendants = []
  if (noeud) {
    let noeudParent = null
    let idNoeudCourant = noeud.identifiantNoeudParent
    if (idNoeudCourant) {
      noeudsAscendants.push(idNoeudCourant)
    }
    do {
      noeudParent = noeuds.filter(
        // eslint-disable-next-line no-loop-func
        (n) => n.identifiantNoeudDimension === idNoeudCourant
      )
      idNoeudCourant = noeudParent?.[0]?.identifiantNoeudParent ?? null
      if (idNoeudCourant) {
        noeudsAscendants.push(idNoeudCourant)
      }
    } while (idNoeudCourant !== null)
  }
  return noeudsAscendants
}

export const getAgentByRecherche = (agents: IAgent[], recherche: string) => {
  // split de rechercher
  const rechercheSplit = recherche.split(' ').filter((r) => r !== '')
  let resultats = agents
  // bouche sur chaque element de recherche en repartant des éléments restants
  for (const element of rechercheSplit) {
    resultats = resultats.filter((agent: IAgent) => {
      const balf = agent.balf
      const tel = telephoneNettoye(agent.coordonnees)
      const portablePro = portableProfessionnelNettoye(agent.coordonnees)
      const bureau = bureauNettoye(agent.coordonnees)
      const idep = idepNettoye(agent.identifiantsAgent)

      return (
        champSansAccent(affichageNomPrenom(agent.etatCivil.nomUsage, agent.etatCivil.prenomUsuel)).includes(element) ||
        tel.includes(element) ||
        portablePro.includes(element) ||
        bureau.includes(element) ||
        idep.includes(element) ||
        balf?.some(
          (item) => champSansAccent(item.nom).includes(element) || champSansAccent(item.adresse).includes(element)
        )
      )
    })
  }
  return resultats
}

export const compareAgentsByName = (a: IAgent, b: IAgent) => {
  return affichageNomPrenom(a.etatCivil.nomUsage, a.etatCivil.prenomUsuel).localeCompare(
    affichageNomPrenom(b.etatCivil.nomUsage, b.etatCivil.prenomUsuel)
  )
}

export const compareUnitesByLibelle = (a: INoeudDimension, b: INoeudDimension) => {
  return a.libelle.localeCompare(b.libelle)
}

export const compareUnitesByIdNoeud = (a: INoeudDimension, b: INoeudDimension) => {
  return a.identifiantNoeudDimension.localeCompare(b.identifiantNoeudDimension)
}

export const getCivilite = (agent: IAgent) => {
  let civilite = ''
  if (agent.etatCivil.civilite?.code) {
    civilite = agent.etatCivil.civilite.code === '1' ? 'M ' : 'Mme '
  }
  civilite += agent.etatCivil.nomUsage + ' ' + agent.etatCivil.prenomUsuel
  return civilite
}

export const getAdresseUnite = (uniteAgent: IUnite) => {
  let adresse = ''

  if (uniteAgent.adresseFrance) {
    adresse = getAdresseFrance(uniteAgent)
  } else if (uniteAgent.adresseEtranger) {
    adresse = getAdresseEtranger(uniteAgent)
  }
  return adresse.split('\n')
}

function getAdresseFrance(uniteAgent: IUnite): string {
  const adresseFrance: { [key: string]: string } = {
    numeroVoie: ' ',
    extension: ' ',
    nomVoie: '\n',
    commune: '',
    complementAdresse: '\n',
    codePostal: ' ',
    bureauDistribution: ''
  }

  let adresse = ''
  for (const key in uniteAgent.adresseFrance) {
    if (key in adresseFrance) {
      if (!uniteAgent.adresseFrance.bureauDistribution && key === 'commune' && uniteAgent.adresseFrance.commune) {
        adresseFrance.commune = uniteAgent.adresseFrance.commune + ' '
      } else if (uniteAgent.adresseFrance[key as keyof IAdresseFrance]) {
        adresseFrance[key as keyof IAdresseFrance] =
          uniteAgent.adresseFrance[key as keyof IAdresseFrance] + adresseFrance[key as keyof IAdresseFrance]
      }
    }
  }
  for (const key in adresseFrance) {
    if (adresseFrance[key as keyof IAdresseFrance] !== ' ' && adresseFrance[key as keyof IAdresseFrance] !== '\n') {
      adresse += adresseFrance[key as keyof IAdresseFrance]
    }
  }
  return adresse
}

function getAdresseEtranger(uniteAgent: IUnite): string {
  const adresseEtranger: { [key: string]: string } = {
    numeroVoie: !uniteAgent.adresseEtranger.complement1 ? '\n' : ' ',
    complement1: '\n',
    complement2: '\n',
    codePostal: ' ',
    ville: uniteAgent.adresseEtranger.ville ? ' - ' : '',
    paysLibelle: ''
  }
  let adresse = ''

  for (const key in uniteAgent.adresseEtranger) {
    if (Object.keys(adresseEtranger).includes(key) && uniteAgent.adresseEtranger[key as keyof IAdresseEtranger]) {
      adresseEtranger[key] = uniteAgent.adresseEtranger[key as keyof IAdresseEtranger] + adresseEtranger[key]
    }
  }
  for (const key in adresseEtranger) {
    if (adresseEtranger[key] !== ' ' && adresseEtranger[key] !== '\n') {
      adresse += adresseEtranger[key]
    }
  }
  return adresse
}
