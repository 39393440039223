import { useCallback, useEffect, useState } from 'react'
import { ChevronRight, ExpandMore } from '@mui/icons-material'
import { SimpleTreeView, TreeItem } from '@mui/x-tree-view'
import { Typography } from '@mui/material'
import { useFetchContext } from '../../hook/UseFetchContext'
import { CLEAR_AGENT, SET_NOEUD_OUVERT, SET_NOEUD_SELECTED } from '../../lib/typeKey'
import { useNavigate } from 'react-router'

const styles = {
  '.MuiTreeItem-content.Mui-selected': {
    backgroundColor: 'selected.cadre'
  },
  '.MuiTreeItem-content.Mui-selected:hover, .MuiTreeItem-content.Mui-selected.Mui-focused': {
    backgroundColor: 'selectedHover.cadre'
  },
  '.MuiTreeItem-content:hover, .MuiTreeItem-content.Mui-focused': {
    backgroundColor: 'hover.cadre'
  },
  '.MuiTreeItem-content:hover': {
    userSelect: 'text'
  }
}

const ArbreUnite = () => {
  const { state, dispatch } = useFetchContext()
  const agents = state.agentState.agents
  const noeuds = state.dimensionState.noeuds
  const noeudOuvert = state.noeudOuvertState.noeudOuvert
  const noeudSelected = state.noeudSelectedState.noeudSelected
  const [noeudsSommet, setNoeudsSommet] = useState<INoeudDimension[]>([])
  const [noeudsAvecEnfants, setNoeudsAvecEnfants] = useState<{ [key: string]: INoeudDimension[] }>({})
  const navigate = useNavigate()

  useEffect(() => {
    // création d'une liste de tous les noeuds avec pour chacun ses enfants
    let newList = {}
    noeuds.forEach(
      (noeud: INoeudDimension) =>
        (newList = {
          ...newList,
          [noeud.identifiantNoeudDimension]: noeuds.filter(
            (n2: INoeudDimension) => n2.identifiantNoeudParent === noeud.identifiantNoeudDimension
          )
        })
    )
    setNoeudsAvecEnfants(newList)
    // récupération dans les données des noeuds sommets, c-a-d ceux pour qui identifiantNoeudParent = null
    setNoeudsSommet(noeuds.filter((noeud: INoeudDimension) => noeud.identifiantNoeudParent === null))
  }, [noeuds])

  // lors d'un click sur un noeud, permet de déplier/replier ce noeud
  const selectionNoeud = (idNoeud: any) => {
    // suppression des agents seulement si on change de noeud
    if (noeudSelected !== idNoeud) {
      dispatch({ type: CLEAR_AGENT })
    }
    dispatch({ type: SET_NOEUD_SELECTED, payload: idNoeud })

    if (noeudOuvert.includes(idNoeud)) {
      const nouveauxNoeuds = noeudOuvert.filter((noeud: string) => noeud !== idNoeud)
      dispatch({ type: SET_NOEUD_OUVERT, payload: nouveauxNoeuds })
    } else {
      const nouveauxNoeuds = [...noeudOuvert]
      nouveauxNoeuds.push(idNoeud)
      dispatch({ type: SET_NOEUD_OUVERT, payload: nouveauxNoeuds })
    }
  }

  const vocaliserUnite = (noeud: INoeudDimension, isNoeudSelected: boolean) => {
    if (isNoeudSelected && agents.length !== 0) {
      return 'Il y a ' + agents.length + " agents dans l'unité " + noeud.libelle.split('~')[0]
    }
    if (isNoeudSelected && agents.length === 0) {
      return "Aucun agent dans l'unité " + noeud.libelle.split('~')[0]
    }
  }

  // ajoute à l'arborescence une modalité et toute sa descendance
  const affichageDescendants = (noeud: INoeudDimension) => {
    const listeFils = noeudsAvecEnfants[noeud.identifiantNoeudDimension] // récupération des noeuds enfants du noeud en paramètre
    const isNoeudSeleced = noeud.identifiantNoeudDimension === noeudSelected
    return (
      // création d'un TreeItem pour le noeud et de manière récursive pour ses enfants
      <TreeItem
        sx={styles}
        contextMenu="true"
        key={noeud.identifiantNoeudDimension}
        itemId={noeud.identifiantNoeudDimension}
        aria-label={vocaliserUnite(noeud, isNoeudSeleced)}
        label={
          <Typography
            component="span"
            noWrap={!isNoeudSeleced}
            style={isNoeudSeleced ? { fontWeight: 'bold' } : {}}
          >
            {noeud.libelle.split('~')[0]}
          </Typography>
        }
        title={noeud.libelle}
      >
        {listeFils.map((fils: any) => affichageDescendants(fils))}
      </TreeItem>
    )
  }

  const onNodeSelect = useCallback(
    (_event: any, id: any) => {
      selectionNoeud(id)
      navigate('/')
    },
    [noeudSelected, noeudOuvert]
  )

  return (
    <SimpleTreeView
      sx={{ userSelect: 'none' }}
      aria-label="navigation dans l'arbre des unités"
      slots={{ collapseIcon: ExpandMore, expandIcon: ChevronRight }}
      expandedItems={noeudOuvert}
      onSelectedItemsChange={onNodeSelect}
      selectedItems={noeudSelected}
    >
      {noeudsSommet.map((n) => affichageDescendants(n))}
    </SimpleTreeView>
  )
}

export default ArbreUnite
