export interface IUnite {
  code: string
  timbre: string
  libelleLong: string
  adresseFrance: IAdresseFrance
  adresseEtranger: IAdresseEtranger
}

export interface IAdresseFrance {
  numeroVoie: string
  extension: string
  nomVoie: string
  complementAdresse: string
  bureauDistribution: string
  commune: string
  codePostal: string
  departement: string
  departementLibelle: string
  dateDebut: Date | null
  dateFin: Date | null
}

export interface IAdresseEtranger {
  numeroVoie: string
  complement1: string
  complement2: string
  codePostal: string
  ville: string
  etatProvince: string
  paysCode: string
  paysLibelle: string
  dateDebut: Date | null
  dateFin: Date | null
}

export const initialAdressseFrance: IAdresseFrance = {
  numeroVoie: '',
  extension: '',
  nomVoie: '',
  complementAdresse: '',
  bureauDistribution: '',
  commune: '',
  codePostal: '',
  departement: '',
  departementLibelle: '',
  dateDebut: null,
  dateFin: null
}

export const initialAdressseEtranger: IAdresseEtranger = {
  numeroVoie: '',
  complement1: '',
  complement2: '',
  codePostal: '',
  ville: '',
  etatProvince: '',
  paysCode: '',
  paysLibelle: '',
  dateDebut: null,
  dateFin: null
}

export const initialUnite: IUnite = {
  code: '',
  timbre: '',
  libelleLong: '',
  adresseFrance: initialAdressseFrance,
  adresseEtranger: initialAdressseEtranger
}
