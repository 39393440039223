import { CircularProgress, Typography } from '@mui/material'

interface ProgressProps {
  texte: string
}

export const Progress = (props: Readonly<ProgressProps>) => {
  const { texte } = props

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '100px'
        }}
      >
        <CircularProgress
          size={100}
          color="secondary"
        />
      </div>
      {texte && (
        <Typography
          variant="h5"
          component="p"
          sx={{ textAlign: 'center', marginTop: '20px' }}
        >
          {texte}
        </Typography>
      )}
    </>
  )
}
