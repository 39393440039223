import { useState, useEffect } from 'react'

const useLoading = (action: () => void | Promise<void>) => {
  const [chargementEnCours, setChargementEnCours] = useState(true)

  useEffect(() => {
    let unmounted = false
    const loadData = async () => {
      setChargementEnCours(true)
      await action()
      if (!unmounted) {
        setChargementEnCours(false)
      }
    }
    loadData()
    return () => {
      unmounted = true
    }
  }, [action])
  return [chargementEnCours, setChargementEnCours]
}

export default useLoading
