import App from '../components/App'
import { RouterType } from './interface/interfaceRoute'

const pagesRoute: RouterType[] = [
  {
    path: '/',
    title: "Annuaire de l'Insee",
    element: <App />,
    children: [
      {
        path: ':idep',
        title: "Annuaire de l'Insee",
        element: <App />,
        children: []
      }
    ]
  }
]

export default pagesRoute
