import { CLEAR_DIMENSION, GET_DIMENSION_SUCCESS } from '../typeKey'
import { compareUnitesByIdNoeud } from '../../components/Utils/fonctions'

export interface INoeudDimensionState {
  noeuds: INoeudDimension[]
  erreur: string
}

export const defaultStateDimension: INoeudDimensionState = {
  noeuds: [],
  erreur: ''
}

const sortUnite = (payload: any) => {
  return payload.sort(compareUnitesByIdNoeud)
}

export default function dimensionReducer(state = defaultStateDimension, action: any) {
  switch (action.type) {
    case GET_DIMENSION_SUCCESS:
      return { ...state, noeuds: sortUnite(action.data.content), erreur: '' }
    case CLEAR_DIMENSION:
      return defaultStateDimension
    default:
      return state
  }
}
