import { makeStyles } from '@mui/styles'
import ArbreUniteBis from './ArbreUnite'
import RechercheUnite from './RechercheUnite'
import { Progress } from '../Utils/Progress'
import { InseeTheme } from '../../theme/theme'
import { useFetchContext } from '../../hook/UseFetchContext'

const useStyles = makeStyles(() => ({
  tree: {
    margin: 0,
    padding: 0,
    maxHeight: '70vh',
    minHeight: '55vh',
    flexGrow: 1,
    overflow: 'auto',
    overflowY: 'scroll',
    overflowX: 'hidden'
  }
}))

export default function BlocUnite() {
  const classes = useStyles(InseeTheme)
  const { state } = useFetchContext()
  const noeuds = state.dimensionState.noeuds

  return noeuds.length > 0 ? (
    <div>
      <RechercheUnite />
      <div className={classes.tree}>
        <ArbreUniteBis />
      </div>
    </div>
  ) : (
    <Progress texte="Chargement des unités en cours..." />
  )
}
