import { GET_HEALTHCHECK } from '../typeKey'

export interface IHealthCheckState {
  healthcheck: boolean | null
}

export const defaultStateHealthcheck: IHealthCheckState = {
  healthcheck: null
}

export default function healthcheckReducer(state = defaultStateHealthcheck, action: any) {
  if (action.type === GET_HEALTHCHECK) {
    return { ...state, healthcheck: action.isOk }
  }
  return state
}
