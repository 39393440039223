import { useCallback, useContext } from 'react'
import { Link } from 'react-router'
import { AppBar, Avatar, CssBaseline, IconButton, Toolbar, Typography } from '@mui/material'
import { IAgent } from '../../lib/interface/agent'
import { getIdNoeudsAscendants, getInitiales } from '../Utils/fonctions'
import { useFetchContext } from '../../hook/UseFetchContext'
import AuthentificationContext from '../../authentification/AuthentificationContext'
import { CLEAR_AGENT, SET_AGENT_SELECTED, SET_NOEUD_OUVERT, SET_NOEUD_SELECTED } from '../../lib/typeKey'

const styles = { avatar: { color: 'primary.main', backgroundColor: 'secondary.main' } }

function getAgentByMatricule(agents: IAgent[], matricule: string) {
  return agents.filter((agent) => agent.identifiantsAgent.matricule === matricule)
}

export default function Header() {
  const { nom, prenom, prenomNom, matricule, idep } = useContext(AuthentificationContext)
  const { state, dispatch } = useFetchContext()
  const agentsTotal = state.agentTotalState.agentsTotal
  const noeuds = state.dimensionState.noeuds
  const photo = state.agentState.photoConnectedAgent
  const titre = 'Annuaire des personnes et services'

  const clickAvatar = (agentsTotal: IAgent[]) => () => {
    if (matricule) {
      const agentMatricule = getAgentByMatricule(agentsTotal, matricule)[0]
      if (agentMatricule) {
        // maj de l'agent sélectionné
        dispatch({ type: SET_AGENT_SELECTED, payload: agentMatricule })
        // maj du noeud sélectionné et des noeuds ouverts
        let idNoeudSelected = null
        let noeudsAscendants: string[] = []
        const noeud = noeuds.filter((n: INoeudDimension) => n.valeurs.includes(agentMatricule.affectation.unite))

        if (noeud.length > 0) {
          idNoeudSelected = noeud[0].identifiantNoeudDimension
          noeudsAscendants = getIdNoeudsAscendants(noeuds, noeud[0])
        }
        dispatch({ type: SET_NOEUD_OUVERT, payload: noeudsAscendants })
        dispatch({ type: SET_NOEUD_SELECTED, payload: idNoeudSelected })
      }
    }
  }

  const clickLogoInsee = useCallback(() => {
    dispatch({ type: CLEAR_AGENT })
    dispatch({ type: SET_NOEUD_OUVERT, payload: [] })
    dispatch({ type: SET_NOEUD_SELECTED, payload: null })
  }, [])

  return (
    <>
      <CssBaseline />
      <AppBar position="relative">
        <Toolbar>
          <Link
            to="/"
            aria-label="accueil"
            onClick={clickLogoInsee}
          >
            <IconButton
              edge="start"
              disabled
            >
              <img
                width="50"
                src={'./Logo_Insee.svg'}
                alt="Logo de l'Insee, cliquez dessus pour la page d'accueil"
              />
            </IconButton>
          </Link>
          <Typography
            variant="h6"
            component="h1"
            sx={{ flexGrow: 1 }}
          >
            {titre}
          </Typography>
          <div style={{ flexGrow: 1 }} />
          <Typography
            variant="body1"
            component="span"
            style={{ marginRight: '15px', fontSize: '1.2rem' }}
          >
            {prenomNom}
          </Typography>
          <Link
            to={`/${idep?.toLowerCase()}`}
            aria-label="votre profil"
            onClick={clickAvatar(agentsTotal)}
          >
            <IconButton
              edge="start"
              disabled
              data-cy="avatar-photo"
            >
              <Avatar
                sx={styles.avatar}
                alt={prenomNom}
                src={'data:image/jpeg;base64,' + photo}
                style={{ width: 60, height: 60 }}
              >
                {getInitiales(nom, prenom)}
              </Avatar>
            </IconButton>
          </Link>
        </Toolbar>
      </AppBar>
    </>
  )
}
