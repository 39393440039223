import { Autocomplete, TextField } from '@mui/material'
import { FormEvent, useCallback, useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router'
import { IAgent } from '../../lib/interface/agent'
import { affichageNomPrenom, champSansAccent, getAgentByRecherche, getIdNoeudsAscendants } from '../Utils/fonctions'
import { useFetchContext } from '../../hook/UseFetchContext'
import { CLEAR_AGENT, SET_AGENT_SELECTED, SET_NOEUD_OUVERT, SET_NOEUD_SELECTED } from '../../lib/typeKey'

export default function RechercheAgent() {
  const { state, dispatch } = useFetchContext()
  const agentsTotal = state.agentTotalState.agentsTotal
  const noeuds = state.dimensionState.noeuds
  const [inputValue, setInputValue] = useState<IAgent | null>(null)
  const location = useLocation()
  const { idep } = useParams()
  const { pathname } = location

  const setListeAgents = useCallback(
    (agent: IAgent) => {
      let idNoeudSelected = null
      let noeud = []
      let noeudsAscendants: string[] = []
      if (agent?.affectation) {
        dispatch({ type: SET_AGENT_SELECTED, payload: agent })
        noeud = noeuds.filter((n: INoeudDimension) => n.valeurs.includes(agent.affectation.unite))
        if (noeud.length > 0) {
          idNoeudSelected = noeud[0].identifiantNoeudDimension
          noeudsAscendants = getIdNoeudsAscendants(noeuds, noeud[0])
        }
      } else {
        dispatch({ type: CLEAR_AGENT })
      }
      dispatch({ type: SET_NOEUD_OUVERT, payload: noeudsAscendants })
      dispatch({ type: SET_NOEUD_SELECTED, payload: idNoeudSelected })
    },
    [noeuds]
  )

  useEffect(() => {
    if (pathname === '/') {
      setInputValue(null)
    } else {
      const agent = agentsTotal.find(
        (value: IAgent) => value.identifiantsAgent.idep?.toUpperCase() === idep?.toUpperCase()
      )
      if (agent) {
        setListeAgents(agent)
      }
    }
  }, [pathname, agentsTotal, idep, setListeAgents])

  // création d'une map avec le code des unités et le timbre que l'on récupère dans le libellé
  const mapCodeLibelle: { [key: string]: string } = {}

  noeuds.forEach((noeud: INoeudDimension) => {
    noeud.valeurs.forEach((valeur: string) => {
      const unite = noeud.libelle.split(' ~ ')[1]
      if (unite) {
        mapCodeLibelle[valeur] = ` (${unite})`
      }
    })
  })

  const searchAgentByName = (recherche: any) => {
    let recherche2 = ''
    // pour éviter les agents qui ont un nom null
    let agentsPartiel = agentsTotal.filter((agent: IAgent) => agent.etatCivil.nomUsage !== null)
    if (recherche) {
      recherche2 = champSansAccent(String(recherche))
    }
    const result = getAgentByRecherche(agentsPartiel, recherche2)
    if (result.length > 0) {
      setInputValue(result[0])
    } else {
      setInputValue(recherche)
    }
    return result
  }

  const miseEnFormeAgentName = (agent: IAgent) => {
    if (mapCodeLibelle[agent.affectation.unite]) {
      return (
        affichageNomPrenom(agent.etatCivil.nomUsage, agent.etatCivil.prenomUsuel) +
        mapCodeLibelle[agent.affectation.unite]
      )
    } else {
      return affichageNomPrenom(agent.etatCivil.nomUsage, agent.etatCivil.prenomUsuel)
    }
  }

  const renderAgentName = useCallback(
    (agent: IAgent | string) => {
      if (!inputValue) {
        return ''
      }
      if (typeof agent === 'object' && agent.affectation) {
        return miseEnFormeAgentName(agent)
      }
      return miseEnFormeAgentName(inputValue)
    },
    [inputValue]
  )

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    if (inputValue) {
      setListeAgents(inputValue)
    }
    event.preventDefault()
  }

  const label = "Recherche d'un agent par nom/prénom, tél, bureau, idep, balf"

  const filterOptions = useCallback((_option: any, value: any) => {
    return searchAgentByName(value.inputValue)
  }, [])

  const onChange = useCallback((_event: any, value: any) => {
    setListeAgents(value)
  }, [])

  const renderInput = useCallback((params: any) => {
    return (
      <TextField
        {...params}
        variant="outlined"
        label={label}
        margin="normal"
        color="secondary"
        slotProps={{
          inputLabel: {
            ...params.inputProps,
            'aria-label': `${label} Les résultats sont affichés à la volée`
          }
        }}
      />
    )
  }, [])

  return (
    <form onSubmit={onSubmit}>
      <Autocomplete
        clearText={'Effacer la saisie'}
        clearOnEscape={true}
        options={agentsTotal}
        getOptionLabel={renderAgentName}
        filterOptions={filterOptions}
        freeSolo
        aria-label={label}
        onChange={onChange}
        renderInput={renderInput}
      />
    </form>
  )
}
