import { useContext, useEffect } from 'react'
import { createBrowserRouter } from 'react-router'
import { RouterProvider } from 'react-router/dom'
import Layout from './layout'
import ErrorInvalid from './error/ErrorInvalid'
import { transformRoutes } from '../routes/interface/interfaceRoute'
import pagesRoute from '../routes/PagesRoute'
import footerRoute from '../routes/FooterRoute'
import { useFetchContext } from '../hook/UseFetchContext'
import AuthentificationContext from '../authentification/AuthentificationContext'
import { GET_AGENT_TOTAL_SUCCESS, GET_DIMENSION_SUCCESS, GET_PHOTO_CONNECTED_AGENT_SUCCESS } from '../lib/typeKey'
import ErrorMaintenance from './error/ErrorMaintenance'

export default function Root() {
  const { matricule } = useContext(AuthentificationContext)
  const { state, fetchGetData, fetchGetPhoto, fetchHealthCheck } = useFetchContext()

  useEffect(() => {
    fetchHealthCheck()
    fetchGetData(
      'agents?Profil=Trombi&populations.etat=actuel&nombre=10000&populations.formateur=false',
      GET_AGENT_TOTAL_SUCCESS
    )
    fetchGetData('noeudsDimension?identifiantDimension=91&nombre=5000&', GET_DIMENSION_SUCCESS)
    if (matricule) {
      fetchGetPhoto(matricule, GET_PHOTO_CONNECTED_AGENT_SUCCESS)
    }
  }, [matricule, fetchGetData, fetchGetPhoto])

  if (state.healthcheckState.healthcheck === null) {
    return <></>
  }

  const router = createBrowserRouter([
    {
      path: '/',
      element: state.healthcheckState.healthcheck ? <Layout /> : <ErrorMaintenance />,
      errorElement: <ErrorInvalid />,
      children: transformRoutes(pagesRoute).concat(transformRoutes(footerRoute))
    }
  ])

  return <RouterProvider router={router} />
}
