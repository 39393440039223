import { RouterType } from './interface/interfaceRoute'
import Accessibilite from '../components/Link/Accessibilite'
import MentionsLegales from '../components/Link/MentionsLegales'
import FaqContact from '../components/Link/FaqContact'
import Securite from '../components/Link/Securite'

const footerRoute: RouterType[] = [
  {
    path: '/accessibilite',
    title: 'Accessibilité',
    testId: 'accessibilite',
    element: <Accessibilite />,
    children: []
  },
  {
    path: '/mentions-legales',
    title: 'Mentions légales',
    testId: 'mentions-legales',
    element: <MentionsLegales />,
    children: []
  },
  {
    path: '/securite',
    title: 'Sécurité',
    testId: 'securite',
    element: <Securite />,
    children: []
  },
  {
    path: '/faq-contact',
    title: ' Faq / Contact',
    testId: 'faq-contact',
    element: <FaqContact />,
    children: []
  }
]

export default footerRoute
