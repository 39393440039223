import { ReactNode, useCallback, useMemo, useState } from 'react'
import { OidcProvider, OidcSecure, useOidcAccessToken, useOidcFetch } from '@axa-fr/react-oidc'
import { Progress } from '../components/Utils/Progress'
import useLoading from '../hook/UseLoading'
import AuthentificationContext from './AuthentificationContext'

interface KeycloakJson {
  'auth-server-url': string
  realm: string
  resource: string
}

function AppWithOidc({
  children
}: Readonly<{
  children: ReactNode
}>) {
  const { fetch: fetchAxafr } = useOidcFetch()
  const { accessTokenPayload } = useOidcAccessToken()

  const contextValue = useMemo(() => {
    const {
      name: prenomNom,
      family_name: nom,
      given_name: prenom,
      matricule,
      preferred_username: idep
    } = accessTokenPayload
    return {
      fetch: fetchAxafr,
      prenomNom,
      nom,
      prenom,
      matricule,
      idep
    }
  }, [fetchAxafr, accessTokenPayload])

  return <AuthentificationContext.Provider value={contextValue}>{children}</AuthentificationContext.Provider>
}

const loadingComponent = () => <Progress texte="Authentification en cours" />

function AuthentificationOidc({
  children
}: Readonly<{
  children: ReactNode
}>) {
  const [keycloakFile, setKeycloakFile] = useState<KeycloakJson | null>(null)

  const [isLoading] = useLoading(
    useCallback(async () => {
      try {
        const keycloakJson = await fetch('/keycloak.json')
        const keycloak = (await keycloakJson.json()) as KeycloakJson
        if (keycloak) {
          setKeycloakFile(keycloak)
        }
      } catch (error: unknown) {
        console.log(`Erreur au chargement du fichier keycloak.json ${error}`)
      }
    }, [])
  )

  if (isLoading) {
    return <Progress texte="Chargement de l'environnement en cours" />
  }
  if (!keycloakFile) {
    return <div>Aucun fichier keycloak.json trouvé</div>
  }

  const configuration = {
    authority: `${keycloakFile['auth-server-url']}/realms/${keycloakFile.realm}`,
    client_id: keycloakFile.resource,
    redirect_uri: `${window.location.origin}/authentication/callback`,
    silent_redirect_uri: `${window.location.origin}/authentication/silent-callback`,
    scope: 'openid profile'
  }

  return (
    <OidcProvider
      configuration={configuration}
      loadingComponent={loadingComponent}
    >
      <OidcSecure>
        <AppWithOidc>{children}</AppWithOidc>
      </OidcSecure>
    </OidcProvider>
  )
}

export default AuthentificationOidc
