const nameAppConfigFile = import.meta.env.VITE_NAME_APP_CONFIG_FILE
  ? import.meta.env.VITE_NAME_APP_CONFIG_FILE
  : 'configuration.json'

interface ProprieteType {
  [key: string]: unknown
}
const proprietes: ProprieteType = {}

const getConfiguration = async <T>(propriete: string) => {
  if (proprietes[propriete]) {
    return Promise.resolve<T>(proprietes[propriete] as T)
  }

  const res = await fetch('/' + nameAppConfigFile)
  const data = await res.json()
  const nouvellePropriete: T = data[propriete]
  proprietes[propriete] = nouvellePropriete
  return Promise.resolve<T>(nouvellePropriete)
}

export default getConfiguration
